<template>
  <div
    class="customButton_container global_container_center customButton_background"
    :class="getClass()"
    :style="getStyle()"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "customButton",
  props: {
    size: {
      // size用于区分 长 中 短
      type: String,
      default: "middle",
    },
    type: {
      // size用于区分 info primary
      type: String,
      default: "primary",
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: {
        height: {
          large: 3,
          middle: 2.5,
          small: 1.6,
        },
      },
    };
  },
  methods: {
    getStyle() {
      let height = this.config.height[this.size];
      let style = `height:${height}rem;line-height:${height}rem;`;
      if (this.round) style = `${style}border-radius:${height / 2}rem;`;
      return style;
    },
    getClass() {
      return `customButton_${this.size} customButton_${this.type}`;
    },
  },
};
</script>
<style >
.customButton_container {
  font-size: 0.8rem;
  /* color:#fff; */
  margin: 0.25rem;
  padding: 0 1rem;
  border-radius: 0.25rem;
  width: auto;
  cursor: pointer;
}

.customButton_primary {
  background: #e64638;
  color: #fff;
}
.customButton_success {
  background: #22b783;
  color: #fff;
}

.customButton_info {
  background: #a0a0a0;
  color: #fff;
}
.customButton_custom {
  background: rgb(254, 232, 241);
  color: #e64638;
}
.customButton_large {
  width: 100%;
}
.customButton_middle {
  min-width: 3.33rem;
}
.customButton_small {
  min-width: 3.33rem;
}
.customButton_background {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
