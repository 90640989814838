<template>
  <div class="popup_container" ref="popup" :style="popupStyle">
    <div class="popup_gray_bg" @click="doFullClose"></div>
    <div class="popup_main">
      <div class="popup_title">
        <div
          class="popup_title_text"
          v-if="msg.title"
          :style="`text-align:${msg.align || 'left'}`"
        >
          <span>{{ msg.title }}</span>
          <div class="popub_close" v-if="msg.close || isClose" @click="doClose">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div class="popup_content" v-if="msg.html" v-html="msg.html"></div>
      <div class="popup_content" v-else>
        <slot></slot>
      </div>
      <div class="popup_btn_group" v-if="!msg.hide">
        <div
          @click="doClose"
          v-if="!msg.btngroup || msg.btngroup.close"
          class="popup_btn_close"
        >
          <CustomButton size="small" type="info">
            <span>{{
              msg.btngroup && typeof msg.btngroup.close == "string"
                ? msg.btngroup.close
                : "取消"
            }}</span>
          </CustomButton>
        </div>
        <div
          @click="doSure"
          v-if="!msg.btngroup || msg.btngroup.sure"
          class="popup_btn_sure"
          :class="{ popup_btn_border: !msg.btngroup || msg.btngroup.close }"
        >
          <CustomButton size="small" :type="msg.info ? msg.info : 'primary'">
            <span>{{
              msg.btngroup && typeof msg.btngroup.sure == "string"
                ? msg.btngroup.sure
                : "确认"
            }}</span>
          </CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "popup",
  components: {
    CustomButton,
  },

  props: {
    msg: {
      type: Object,
      default: () => {
        return {
          title: "",
          content: "",
          align: "center",
          isClose: false,
        };
      },
    },
    isHover: {
      type: Boolean,
      default: false,
    },
    isClose: {
      type: Boolean,
      default: true,
    },
    doCancel: {
      type: Function,
      default: () => {},
    },
    doComfirm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      popupStyle: "",
    };
  },
  created() {
    console.log("this.msg :>> ", this.msg);
    this.$nextTick(() => {
      let clientWidth = window.innerWidth;
      let popupWidth = document.body.clientWidth;
      this.popupStyle = `width:calc(${popupWidth}px);left:${
        (clientWidth - popupWidth) / 2
      }px`;
    });
  },
  methods: {
    doFullClose() {
      // if (!this.msg.isNotFullClose) {
      //   this.doClose();
      // }
    },
    doClose() {
      if (this.isHover) {
        this.doCancel();
      } else {
        this.$emit("doClose");
      }
    },
    doSure() {
      if (this.isHover) {
        this.doComfirm();
      } else {
        this.$emit("doSure");
      }
    },
  },
};
</script>
<style>
.popup_container {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  line-height: 1.5;
}
.popup_gray_bg {
  height: 100%;
  width: 100%;
  background: #999;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.popup_main {
  position: relative;
  z-index: 1;
  width: 80%;
  padding: 1rem 1rem;
  background: #fff;
  border-radius: 0.5rem;
}
.popup_title {
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
  color: #e64638;
  /* color:#666; */
  /* padding-bottom: 1rem; */
}
.popup_title_text {
  position: relative;
  text-align: center;
  font-size: 0.9rem;
}
.popub_close {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1rem;
}
.popup_content {
  /* min-height:8.8rem; */
  font-size: 1rem;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  flex-direction: column;
}
.popup_btn_group {
  /* height: 3rem; */
  display: flex;
  /* border-top: 1px solid #e6e6e6; */
  /* font-weight: 600; */
}
.popup_btn_sure > span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.popup_btn_border {
  /* border-right: 1px solid #e6e6e6; */
}
.popup_btn_sure {
  color: #e64638;
}
.popup_btn_group div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1rem; */
}
.popup_btn_close,
.popup_btn_sure {
  flex: 1 0 0;
}

.popup-content-main {
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding: 1rem 0;
  white-space: pre-line;
  text-align: center;
}
</style>